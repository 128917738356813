.navbar {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1rem;
  height: 64px;
}

.navbar-brand {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.navbar-icons {
  display: flex;
}

.navbar-icon {
  margin-left: 1.5rem;
}

.navbar-icon:first-child {
  margin-left: 0;
}

.navbar-icon a {
  color: #333;
  font-size: 1.4rem;
  transition: all 0.2s;
}

.navbar-icon a:hover {
  color: #555;
}
