.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  
  .home-image {
    height: 500px;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    
  }
  
  .home-image::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Change this value to adjust the darkness */
  }
  
  .home-image-overlay {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    text-align: left;
    white-space: nowrap; 
    width: 50%;
  }
  
  .home-image-overlay h1 {
    font-size: 48px;
    font-weight: bold;
    margin: 0;
    padding: 0;
 
  }
  
  .home-image-overlay p {
    font-size: 19px;
 
  }
  
  .home-image-overlay .btn {
    font-size: 20px;
    padding: 10px 30px;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    border: none;
    margin-top: 50px;
  }
  
  .home-image-overlay .btn:hover {
    background-color: #0062cc;
  }

  @media screen and (max-width: 720px) {
    .home-image-overlay {
      top: 50%;
      left: 50%;
      text-align: center;
      white-space: normal;
      padding: 2rem;
    }
  
    .home-image-overlay h1 {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
  
    .home-image-overlay p {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  
    .home-image-overlay button {
      font-size: 1rem;
    }
  }

  .services {
    text-align: center;
    margin: 50px auto;
    max-width: 800px;
    font-size: large;
    padding: 10px;
  }
  
  .offer-list {
    text-align: center;
    
  }
  
  .offer-list li {
  
    margin-left: 0;
    list-style: none; 
  }

  .solar-section {
    font-weight: bold;
  }

  .pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  @media screen and (min-width: 768px) {
    .pricing-container {
      padding: 10px;
          border: 5px solid rgba(0, 0, 0, 0.037);
    }
  }
  
  .pricing-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  @media screen and (min-width: 768px) {
    .pricing-row {
      flex-direction: row;
    }
  }
  
  .pricing-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }

  
  @media screen and (min-width: 768px) {
    .pricing-item {
      margin: 0 20px;
    }
  }
  
  .pricing-item p:first-of-type {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  @media screen and (min-width: 768px) {
    .pricing-item p:first-of-type {
      font-size: 48px;
    }
  }
  
  .pricing-item p:last-of-type {
    font-style: italic;
    font-size: 14px;
  }
  
  @media screen and (min-width: 768px) {
    .pricing-item p:last-of-type {
      font-size: 18px;
    }
  }


  .form-container {
  width: 75%;
  max-width: 600px;
  margin: 40px auto;
  font-family: 'Open Sans', sans-serif;
}

.form-container p{
  margin-bottom: 30px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #444;
}

.form-input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-family: inherit;
}

.form-input:focus {
  outline: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.form-submit {
  background-color: #1a73e8;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-submit:hover {
  background-color: #0f52ba;
}

.form-textarea {
  display: block;
  width: 100%;
  height: 150px;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-family: inherit;
}

.form-textarea:focus {
  outline: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.whatsapp-icon p{
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0;
}

.whatsapp-icon:first-child {
  margin-left: 0;
}

.whatsapp-icon a {
  color: #333;
  font-size: 2rem;
  transition: all 0.2s;
}

.whatsapp-icon a:hover {
  color: #555;
}

.fail-message {
  color: #ff0000; /* set the text color to red */
  font-size: 14px; /* adjust the font size as desired */
  font-weight: bold; /* make the text bold for emphasis */
  margin-top: 5px; /* add some margin to separate it from other elements */
}

.success-message {
    color: #008000; /* set the text color to green */
    font-size: 14px; /* adjust the font size as desired */
    font-weight: bold; /* make the text bold for emphasis */
    margin-top: 5px; /* add some margin to separate it from other elements */
}
